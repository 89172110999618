<template>
  <v-card height="100%" width="100%" class="grey darken-3 fill-height">
    <v-row
      height="100%"
      dense
      width="100%"
      no-gutters
    >
      <v-col
        v-for="(track, i) in tracks"
        :key="i"
        cols="12"
      >
        <track-player-card ref="trackCard" :track="track" :showButton="true" @playing="pauseOtherTracks" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'Tunes',
  components: {
    TrackPlayerCard: () => import('../../components/TrackPlayerCard.vue')
  },
  computed: {
    tracks () {
      return this.$store.state.tunes.mixes
    }
  },
  methods: {
    pauseOtherTracks (trackId) {
      console.log(trackId)
      const that = this
      this.tracks.forEach((track, i) => {
        if (trackId !== track.id) {
          that.$refs.trackCard[i].pausedByOtherTrack()
        }
      })
    }
  },
  created () {
    this.$store.dispatch('tunes/initialise')
  },
  mounted () {}
}
</script>
